import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLocalized from '../../Data/Localization';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import TabPanel from '../TabPanel/TabPanel';
import { makeStyles } from '@material-ui/core';
import PartnerListTable from '../PartnerListTable/PartnerListTable';

const PartnerList = ({
  presentedPartners,
  handlePartnerDataChange
}) => {
  /* MARK: - Component states */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePartners, setActivePartners] = useState([]);
  const [inactivePartners, setInactivePartners] = useState([]);

  /* MARK: - Component styles */
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 350,
    },
  }));

  const classes = useStyles();

  /* MARK: - useEffect hook */
  useEffect(() => {
    separateActiveAndInactivePartners(presentedPartners);
  }, [presentedPartners])

  /**
   * sort partners array in alphabetical order based on:
   * 1. companyName
   * 2. location
   */
  const sortPartners = (partners) => {
    return partners.sort((a, b) => {
      const companyComparison = a.companyName.localeCompare(b.companyName);
      if (companyComparison !== 0) {
        return companyComparison;
      }
      return a.location.localeCompare(b.location);
    });
  };

  /* MARK: - Handler functions */
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  }, []);

  const handleTabChange = (event, newIndex) => {
    setActiveIndex(newIndex);
  };

  /* MARK: - Helper functions */
  const separateActiveAndInactivePartners = (partners) => {
    const tempActivePartners = [];
    const tempInactivePartners = [];

    partners.forEach(partner => {
      /**
       * Temporarily added ! in front of this condition as 
       * currently the partner objects do not have such as 
       * 'isActive' field. This will be later added in the BE.
       * Once that happens the ! mark has to be removed.
       */
      if (!partner.isActive) {
        tempActivePartners.push(partner);
      } else {
        tempInactivePartners.push(partner);
      }
    });

    const sortedActivePartners = sortPartners(tempActivePartners);
    const sortedInactivePartners = sortPartners(tempInactivePartners);

    setActivePartners(sortedActivePartners);
    setInactivePartners(sortedInactivePartners);
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  /* MARK: - Component render method */
  return (
    <>
      <AppBar className={classes.root} position='static' color='default' elevation={1}>
        <Tabs 
          value={activeIndex}
          variant='fullWidth'
          onChange={handleTabChange}
        >
          <Tab label={`${useLocalized('partners_active_title')}`} {...a11yProps(0)} />
          <Tab label={`${useLocalized('partners_inactive_title')}`} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={activeIndex} index={0}>
        <PartnerListTable 
          partners={activePartners}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePartnerDataChange={handlePartnerDataChange}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TabPanel>
      <TabPanel value={activeIndex} index={1}>
        <PartnerListTable 
          partners={inactivePartners}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePartnerDataChange={handlePartnerDataChange}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TabPanel>
    </>
  );
};

/* MARK: - Prop types */
PartnerList.propTypes = {
  partners: PropTypes.array,
  handlePartnerDataChange: PropTypes.func,
};

export default PartnerList;
