import React from 'react';
import useLocalized from '../../Data/Localization';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import DateService from '../../Services/DateService';
import DriversTimesheetCalendar from '../../Components/DriversTimesheetCalendar/DriversTimesheetCalendar';
import PriceService from '../../Services/PriceService';
import TimesheetNote from '../../Components/TimesheetNote/TimesheetNote';
import useScrollSync from '../../CustomHooks/useScrollSync';
import { useEffect, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    sccont: {
        marginTop: 80,
    },
}));

const DriversTimesheet = ({
    timesheets,
    dateFrom,
    dateTo,
    weekNumber,
    getTimesheetByDriver,
}) => {

    const classes = useStyles();
    const labelNr = useLocalized('drivers_table_head_cell_nr');
    const labelDriverName = useLocalized('drivers_table_head_cell_driver_name');
    const days = ['M', 'Tu', 'W', 'Th', 'Fr', 'Sa', 'Su'];
    const vanVehicleType = useLocalized('vehicle_type_van');
    const hiabVehicleType = useLocalized('vehicle_type_hiab');
    const specialVehicleType = useLocalized('vehicle_type_special');
    const vanPayrollVehicleType = useLocalized('vehicle_type_van_payroll');
    const hiabPayrollVehicleType = useLocalized('vehicle_type_hiab_payroll');
    const loftLVehicleType = useLocalized('vehicle_type_loft_l');
    const sevenTonsVehicleType = useLocalized('vehicle_type_seven_tons');
    const hoursTextLabel = useLocalized('driver_jobs_header_hours_label');
    const hoursOvertimeLabel = useLocalized('driver_jobs_header_overtime_label');
    const driverTotalHours = useLocalized('driver_total_hours_label');
    const driverPayment = useLocalized('driver_payment_label');
    const driverTotalPayment = useLocalized('driver_total_payment_label');
    const driverTimesheetNote = useLocalized('driver_timesheet_note');
    const companyProfitLabel = useLocalized('company_profit_label');
    const companyInvoiceLabel = useLocalized('nav_invoice');
    const companyTotalLabel = useLocalized('company_total_label');
    const vehicleTypeAlertMessage = useLocalized('vehicle_type_filter_alert_message');

    const headerItemsRef = useRef([]);
    const weekItemsRef = useRef([]);

    useScrollSync('syncscroll');

    // Function to synchronize widths of header items with week list items
    const syncHeaderAndTableWidths = () => {
        if (headerItemsRef.current && weekItemsRef.current) {
            headerItemsRef.current.forEach((headerItem, index) => {
                const weekItem = weekItemsRef.current[index];
                if (headerItem && weekItem) {
                    const width = weekItem.offsetWidth;
                    headerItem.style.width = `${width}px`;
                }
            });
        }
    };

    const getTimesheetWeekDays = (driverJob, driverId, hasHourlyRates) => {
        const timesheet = [];
        let date = new Date(dateFrom)
        for (let day = 0; day < 7; day++) {
            let newDate = DateService.convertDate(date)
            timesheet.push(<DriversTimesheetCalendar
                day={days[day]}
                date={newDate}
                hasHourlyRates={hasHourlyRates}
            />);
        }
        driverJob.forEach((drivertimesheet) => {
            const itemDate = new Date(drivertimesheet.date);
            const dayNumber = DateService.getWeekDay(itemDate);
            timesheet[dayNumber] = (
                <DriversTimesheetCalendar
                    idjob={drivertimesheet.id}
                    day={days[dayNumber]}
                    date={drivertimesheet.date}
                    driverId={driverId}
                    companyName={drivertimesheet.order_company}
                    companyLocation={drivertimesheet.order_location}
                    vehicleType={drivertimesheet.vehicle_type}
                    hours={drivertimesheet.hours}
                    overtimeHours={drivertimesheet.overtime_hours}
                    adminModifiedHours={drivertimesheet.admin_modified_hours}
                    adminModifiedOvertimeHours={drivertimesheet.admin_modified_overtime_hours}
                    getTimesheetByDriver={getTimesheetByDriver}
                    vehicleTypeAlertMessage={vehicleTypeAlertMessage}
                    hasHourlyRates={hasHourlyRates}
                />
            );
        });
        return timesheet;
    };

    useEffect(() => {
        syncHeaderAndTableWidths();
    }, [timesheets]);

    return (
        <>
            <Grid container className={classes.root} spacing={2}>
                <Grid container className={classes.sccont} spacing={0}>

                    <div className="timesheet-header-wrapper">
                        <div className="scroller syncscroll" name="scrollSyncedElement">
                            {timesheets.timesheetData.map((value, nrdriver) => (
                                <div 
                                    className="driver-schedule-header" 
                                    key={nrdriver}
                                    ref={(el) => (headerItemsRef.current[nrdriver] = el)}>
                                    <label className="labelCustom">{labelNr}</label>
                                    <div className="driver-info-section">
                                        <div className="driver-info">
                                            <div className="driver-color-row" style={{ backgroundColor: '#' + value.driver_details.driver_color_code }}></div>
                                            <div className="driver-nr">#{value.driver_details.driver_number}</div>
                                        </div>
                                        <div className="driver-location">{value.driver_details.driver_location}</div>
                                    </div>
                                    <div className="driver-name-row">
                                        <label className="labelCustom">{labelDriverName}</label>
                                        <div className="driver-name">{value.driver_details.driver_name}</div>
                                    </div>
                                </div> 
                            ))}
                            <Divider />
                        </div>
                    </div>

                    <div className="scrolling-wrapper-flexbox syncscroll" name="scrollSyncedElement">
                        {timesheets.timesheetData.map((value, nrdriver) => (
                            <Grid 
                                className="driver-timesheet-list" 
                                xs={false} 
                                key={nrdriver} 
                                item
                                ref={(el) => (weekItemsRef.current[nrdriver] = el)}>
                                <Paper>
                                    <div className="week-row-to-schedule">
                                        <div className="timesheet-header-info">
                                            <div className="empty-header-col"></div>
                                            { value.driver_details.has_van_hourly_rate && <div className="vehicle-type-col-ext">{vanVehicleType}</div> }  
                                            { value.driver_details.has_van_payroll_hourly_rate && <div className="vehicle-type-col-ext">{vanPayrollVehicleType}</div> }  
                                            { value.driver_details.has_hiab_hourly_rate && <div className="vehicle-type-col-ext">{hiabVehicleType}</div> }
                                            { value.driver_details.has_hiab_payroll_hourly_rate && <div className="vehicle-type-col-ext">{hiabPayrollVehicleType}</div> }  
                                            { value.driver_details.has_seven_tons_hourly_rate && <div className="vehicle-type-col-ext">{sevenTonsVehicleType}</div> }  
                                            { value.driver_details.has_loft_l_hourly_rate && <div className="vehicle-type-col-ext">{loftLVehicleType}</div> }
                                            { value.driver_details.has_special_hourly_rate && <div className="vehicle-type-col-ext">{specialVehicleType}</div> }
                                        </div>
                                        <div className="timesheet-header-info">
                                            <div className="empty-header-col"></div>
                                            { value.driver_details.has_van_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }                                            
                                            { value.driver_details.has_van_payroll_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_hiab_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }      
                                            { value.driver_details.has_hiab_payroll_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }      
                                            { value.driver_details.has_seven_tons_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_loft_l_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }      
                                            { value.driver_details.has_special_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col-ext">{hoursTextLabel}</div>
                                                    <div className="hours-quantity-col-ext">{hoursOvertimeLabel}</div>
                                                </> )
                                            }            
                                        </div>
                                        <div className="week-nr-col">
                                            {nrdriver === 0 && <div className="note-date">W{weekNumber}</div>}
                                            {nrdriver > 0 && <div className="note-date">&nbsp;</div>}
                                        </div>
                                        <div className="driver-week-list">
                                            {getTimesheetWeekDays(value.driver_jobs, value.driver_details.driver_id, value.driver_details)}
                                        </div>
                                        <div className="timesheet-header-info driver-payment-details">
                                            <div className="week-empty-col"></div>
                                            <div className="empty-header-payment-col">{driverTotalHours}</div>
                                            { value.driver_details.has_van_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_van !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_van) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_van !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_van) : 0}</div>
                                                </>)
                                            }
                                            { value.driver_details.has_van_payroll_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_van_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_van_payroll) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_van_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_van_payroll) : 0}</div>
                                                </>)
                                            }
                                            { value.driver_details.has_hiab_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_hiab !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_hiab) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_hiab !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_hiab) : 0}</div>
                                                </>)
                                            }
                                            { value.driver_details.has_hiab_payroll_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_hiab_payroll) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_hiab_payroll) : 0}</div>
                                                </>)
                                            }
                                            { value.driver_details.has_seven_tons_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_seven_tons !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_seven_tons) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_seven_tons !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_seven_tons) : 0}</div>
                                                </>)
                                            }
                                            { value.driver_details.has_loft_l_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_loft_l !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_loft_l) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_loft_l !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_loft_l) : 0}</div>
                                                </>)
                                            }
                                            { value.driver_details.has_special_hourly_rate && (
                                                <>       
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_hours_special !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_hours_special) : 0}</div>
                                                    <div className="hours-quantity-col">{value.driver_payment_details.total_overtime_special !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.total_overtime_special) : 0}</div>
                                                </>)
                                            }
                                        </div>
                                        <div className="timesheet-header-info driver-payment-details">
                                            <div className="week-empty-col"></div>
                                            <div className="empty-header-payment-col driver-payment-details-line">{driverPayment}</div>
                                            { value.driver_details.has_van_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_van !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_van ) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_van !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_van) : 0}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_van_payroll_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_van_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_van_payroll) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_van_payroll  !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_van_payroll) : 0}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_hiab_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_hiab !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_hiab) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_hiab !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_hiab) : 0}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_hiab_payroll_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_hiab_payroll) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_hiab_payroll) : 0}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_seven_tons_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_seven_tons !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_seven_tons) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_seven_tons !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_seven_tons) : 0}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_loft_l_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_loft_l !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_loft_l) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_loft_l !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_loft_l) : 0}</div>
                                                </> )
                                            }
                                            { value.driver_details.has_special_hourly_rate && (
                                                <>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_special !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_special) : 0}</div>
                                                    <div className="hours-quantity-col driver-payment-details-line">{value.driver_payment_details.payment_overtime_special !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_overtime_special) : 0}</div>
                                                </> )
                                            }
                                        </div>
                                        <div className="timesheet-header-info driver-payment-details">
                                            <div className="week-empty-col"></div>
                                            <div className="empty-header-col-details">{driverTotalPayment}</div>
                                            <div className="driver-payment-details-total">{value.driver_payment_details.payment_total !== 0.0 ? PriceService.getFormattedPrice(value.driver_payment_details.payment_total) : 0}</div>
                                        </div>
                                        <div className="timesheet-header-info driver-timesheet-note">
                                            <div className="week-empty-col"></div>
                                            <div className="empty-header-col-details driver-payment-details-line">{driverTimesheetNote}</div>
                                            <div className="driver-payment-details-total driver-payment-details-line">
                                                <TimesheetNote
                                                    noteTimesheet={value.timesheet_note}
                                                    dateFrom={dateFrom}
                                                    dateTo={dateTo}
                                                    driverId={value.driver_details.driver_id}
                                                />
                                            </div>
                                        </div>
                                        {value.company_payment_list.map((cplist) => (
                                            <div className="company-payment-list">
                                                <div className="timesheet-header-info driver-payment-details">
                                                    <div className="week-empty-col"></div>
                                                    <div className="empty-header-payment-col">{cplist.company_name} {companyTotalLabel}</div>
                                                    { value.driver_details.has_van_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_van !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_van) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_van !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_van) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_van_payroll_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_van_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_van_payroll) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_van_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_van_payroll) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_hiab_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_hiab !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_hiab) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_hiab !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_hiab) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_hiab_payroll_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_hiab_payroll) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_hiab_payroll) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_seven_tons_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_seven_tons !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_seven_tons) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_seven_tons !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_seven_tons) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_loft_l_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_loft_l !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_loft_l) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_loft_l !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_loft_l) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_special_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.total_hours_special !== 0.0 ? PriceService.getFormattedPrice(cplist.total_hours_special) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.total_overtime_special !== 0.0 ? PriceService.getFormattedPrice(cplist.total_overtime_special) : 0}</div>
                                                        </> )
                                                    }
                                                </div>
                                                <div className="timesheet-header-info driver-payment-details">
                                                    <div className="week-empty-col"></div>
                                                    <div className="empty-header-payment-col">{cplist.company_name} {companyInvoiceLabel}</div>
                                                    { value.driver_details.has_driver_van_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_van !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_van) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_van !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_van) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_van_payroll_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_van_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_van_payroll) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_van_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_van_payroll) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_hiab_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_hiab !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_hiab) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_hiab !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_hiab) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_hiab_payroll_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_hiab_payroll) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_hiab_payroll) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_seven_tons_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_seven_tons !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_seven_tons) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_seven_tons !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_seven_tons) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_loft_l_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_loft_l !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_loft_l) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_loft_l !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_loft_l) : 0}</div>
                                                        </> )
                                                    }
                                                    { value.driver_details.has_special_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.payment_special !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_special) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.payment_overtime_special !== 0.0 ? PriceService.getFormattedPrice(cplist.payment_overtime_special) : 0}</div>
                                                        </> )
                                                    }
                                                </div>
                                                <div className="timesheet-header-info driver-payment-details">
                                                    <div className="week-empty-col"></div>
                                                    <div className="empty-header-payment-col">{companyProfitLabel}</div>
                                                    { value.driver_details.has_van_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_van !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_van) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_van !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_van) : 0}</div>
                                                        </>)
                                                    }
                                                    { value.driver_details.has_van_payroll_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_van_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_van_payroll) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_van_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_van_payroll) : 0}</div>
                                                        </>)
                                                    }
                                                    { value.driver_details.has_hiab_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_hiab !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_hiab) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_hiab !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_hiab) : 0}</div>
                                                        </>)
                                                    }
                                                    { value.driver_details.has_hiab_payroll_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_hiab_payroll) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_hiab_payroll !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_hiab_payroll) : 0}</div>
                                                        </>)
                                                    }
                                                    { value.driver_details.has_seven_tons_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_seven_tons !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_seven_tons) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_seven_tons !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_seven_tons) : 0}</div>
                                                        </>)
                                                    }
                                                    { value.driver_details.has_loft_l_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_loft_l !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_loft_l) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_loft_l !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_loft_l) : 0}</div>
                                                        </>)
                                                    }
                                                    { value.driver_details.has_special_hourly_rate && (
                                                        <>
                                                            <div className="hours-quantity-col">{cplist.profit_hours_special !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_hours_special) : 0}</div>
                                                            <div className="hours-quantity-col">{cplist.profit_overtime_special !== 0.0 ? PriceService.getFormattedPrice(cplist.profit_overtime_special) : 0}</div>
                                                        </>)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        <div className="clr"></div>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    const {
        timesheets: { status },
    } = state;
    return { status };
};

export default connect(mapStateToProps, null)(DriversTimesheet);
