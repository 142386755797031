import React, { useEffect, useState, useCallback, useMemo } from 'react';
import useLocalized from '../../Data/Localization';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopTitle from '../../Components/TopTitle/TopTitle';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import { getPartners, createPartner } from '../../Redux/Actions/PartnerActions';
import { setPartnerData } from '../../Redux/Actions/PartnerDataAction';
import { SUCCESS, LOADING, FAIL} from '../../Redux/ActionTypes';
import PartnerList from '../../Components/PartnerList/PartnerList';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import PartnerPopup from '../../Components/PartnerPopup/PartnerPopup';

const Partners = ({
  partners: {
    status: partnerStatus,
    data: partnerData,
  },
  getPartners,
  createPartner,
  setPartnerData
}) => {

  const dataSavedMessage = useLocalized('partners_data_successfully_updated');
  const [presentedPartners, setPresentedPartners] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [editedPartners, setEditedPartners] = useState({});

  useEffect(() => {
    setTimeout(() => {
      getPartners();
    }, 0);
  }, [getPartners]);

  const getSortedPartners = useCallback((partners) => {
    return partners.sort(({ id: a }, { id: b }) => a.localeCompare(b));
  }, []);

  useEffect(() => {
    if (partnerData.partnersData) {
      setPresentedPartners(getSortedPartners(partnerData.partnersData));
    }
  }, [getSortedPartners, partnerData]);

  const handlePartnerDataChange = useCallback((columnId, rowIndex, value) => {
    setPresentedPartners((currentPartners) => {
      const updatedRow = {
        ...currentPartners[rowIndex],
        [columnId]: value,
      };
  
      setEditedPartners((prev) => ({
        ...prev,
        [updatedRow.id]: {
          ...prev[updatedRow.id],
          [columnId]: value,
        },
      }));
  
      const updatedPartners = [...currentPartners];
      updatedPartners[rowIndex] = updatedRow;
      return getSortedPartners(updatedPartners);
    });
  }, [getSortedPartners]);

  const savePartnerData = useCallback(() => {
    Object.entries(editedPartners).forEach(([partnerId, partnerChanges]) => {
      const editedPartner = {
        company_name: partnerChanges.companyName,
        location: partnerChanges.location,
        hourly_rate_van: partnerChanges.hourlyRateVan,
        overtime_hourly_rate_van: partnerChanges.overtimeHourlyRateVan,
        hourly_rate_hiab: partnerChanges.hourlyRateHiab,
        overtime_hourly_rate_hiab: partnerChanges.overtimeHourlyRateHiab,
        hourly_rate_special: partnerChanges.hourlyRateSpecial,
        overtime_hourly_rate_special: partnerChanges.overtimeHourlyRateSpecial,
        hourly_rate_van_payroll: partnerChanges.hourlyRateVanPayroll,
        overtime_hourly_rate_van_payroll: partnerChanges.overtimeHourlyRateVanPayroll,
        hourly_rate_hiab_payroll: partnerChanges.hourlyRateHiabPayroll,
        overtime_hourly_rate_hiab_payroll: partnerChanges.overtimeHourlyRateHiabPayroll,
        hourly_rate_seven_tons: partnerChanges.hourlyRateSevenTons,
        overtime_hourly_rate_seven_tons: partnerChanges.overtimeHourlyRateSevenTons,
        hourly_rate_loft_l: partnerChanges.hourlyRateLoftL,
        overtime_hourly_rate_loft_l: partnerChanges.overtimeHourlyRateLoftL,
      };
  
      setPartnerData(partnerId, editedPartner, () => {
        setSuccessMessage(dataSavedMessage);
        setTimeout(() => setSuccessMessage(""), 5000);
      });
    });
  
    setEditedPartners({});
  }, [editedPartners, setPartnerData, dataSavedMessage]);
  
  const canSave = useMemo(() => {
    return Object.keys(editedPartners).length > 0;
  }, [editedPartners]);

  const handleSave = useCallback((createPartnerData) => {
    createPartner(createPartnerData)
  }, [createPartner])

  return (
    <>
      <div className='title-container'>
        <TopTitle title={`${useLocalized('partners_title')}`} subtitle={`${useLocalized('partners_subtitle')}`} />
        <div>
          <button className='partner-buttons' disabled={!canSave} onClick={savePartnerData}>
            {useLocalized('save_button_text')}
          </button>
          <button className='partner-buttons' onClick={() => setOpen((oldState) => !oldState)}>
            {useLocalized('new_partner_button_text')}
          </button>
        </div>
      </div>
      {<PartnerPopup action={handleSave} handleOpenClose={() => setOpen((oldState) => !oldState)} dialogOpen={open}/>}
      <FormHelperText component="div"><div className="saved-successfully-data-text partner-saved-text">{successMessage}</div></FormHelperText>
      {partnerStatus === LOADING && <div className="loading-icon"><CircularProgress /></div>}
      {partnerStatus === SUCCESS && <PartnerList presentedPartners={presentedPartners} handlePartnerDataChange={handlePartnerDataChange} />}
      {partnerStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
    </>
  );
};

Partners.propTypes = {
  partners: PropTypes.object,
  getPartners: PropTypes.func,
  setPartnerData: PropTypes.func,
};

export default connect(({
  partners,
}) => ({
  partners,
}), {
  getPartners,
  createPartner,
  setPartnerData
})(Partners);
